import React, { memo } from 'react'
import icon from '../../../assets/images/icon-11.png'
import { getImage } from 'shared/utills'
import { Link } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'

const LearningTrackSildeMb = memo(
  ({ course, }) => {
    let isLgPage =false
    const route = useRouteMatch()
    if(route.path === '/courses/lg'){
      isLgPage = true
    }
    if (!course) return <div />
    let totalINR = 0
    return (
      <div className="new-locatblk text-left">
        <div className="outdiv">
          <div
            className={
              'item bnd-three-blk animation-box m-auto ' + course?.cssClass
            }
          >
            <div className="three-blk outdiv h-auto mb-0">
              <h5>{course?.name}</h5>
              <span className="thre-rghimg">
                {course?.robotImage ? (
                  <img
                    className=" ls-is-cached lazyloaded"
                    alt={course.name}
                    height={'100%'}
                    width={'100%'}
                    src={
                      course?.isStatic
                        ? course.robotImage
                        : getImage(course?.robotImage)
                    }
                  />
                ) : null}
              </span>
            </div>
            <div
              className="innerdiv p-3 animation-inner-box"
              style={{ width: '100%' }}
            >
              <ul className="timing" style={{ width: '100%' }}>
                <li style={{ width: '100%' }}>
                  <img
                    className="lazyload"
                    src={icon}
                    alt=""
                    style={{ display: 'inner-block', width: 'auto' }}
                  />{' '}
                  <span>
                    <b>{course?.hoursCount}</b> hours
                    {course?.yearCount && (
                      <>
                        | <b>{course?.yearCount}</b> Months
                      </>
                    )}
                  </span>
                </li>
              </ul>
              <h6 className="expand-h6">
                {course.isStatic
                  ? 'Job-focussed skills on:'
                  : 'Course Included:'}
              </h6>
              <div className="inner-total">
                {course.courseIncludedRecord &&
                  course.courseIncludedRecord.map((item, index) => {
                    totalINR = totalINR + item.amountINR
                    return (
                      <ul className="countpay" key={index}>
                        <li className="countpy-detal">
                          {item?.name}{' '}
                          {item?.amountINR && (
                            <span className="pay-ttl">
                              ₹ {item?.amountINR}/-
                            </span>
                          )}
                        </li>
                      </ul>
                    )
                  })}
                {totalINR ? (
                  <ul className="countpay-totl">
                    <li className="countpay-totlpay-ttl">₹ {totalINR}/-</li>
                  </ul>
                ) : null}
              </div>
              {course?.isEMIShow && course?.totalAmount ? (
                <div className="total-head-prc">
                  ₹{course?.totalAmount}​/- EMI<sup>*</sup>
                </div>
              ) : null}
              {!course?.isEMIShow && (
                  <div className="total-head-prc">₹{course?.totalAmount}​</div>
              )}
              {/* <a href="business-analytics-data-science-course" className="totl-buton">View Details</a> */}
              <Link className="totl-buton" to={isLgPage ? `/${course?.slug}/lg` :`/${course?.slug}`}>
                View Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  },
  (memoPrevProps, memoNextProps) => {
    if (memoPrevProps.dummy !== memoNextProps.dummy) {
      return false
    } else {
      return true
    }
  },
)
export default LearningTrackSildeMb
