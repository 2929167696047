import {dataScienceWithAiSpecializationSlug} from 'data/courseStaticPage'
import React from 'react'
export const countryCode = [
  '+1',
  '+7',
  '+20',
  '+27',
  '+30',
  '+31',
  '+32',
  '+33',
  '+34',
  '+36',
  '+39',
  '+40',
  '+41',
  '+43',
  '+44',
  '+45',
  '+46',
  '+47',
  '+48',
  '+49',
  '+51',
  '+52',
  '+53',
  '+54',
  '+55',
  '+56',
  '+57',
  '+58',
  '+60',
  '+61',
  '+62',
  '+63',
  '+64',
  '+65',
  '+66',
  '+81',
  '+82',
  '+84',
  '+86',
  '+90',
  '+91',
  '+92',
  '+93',
  '+94',
  '+95',
  '+98',
  '+212',
  '+213',
  '+216',
  '+218',
  '+220',
  '+221',
  '+222',
  '+223',
  '+224',
  '+225',
  '+226',
  '+227',
  '+228',
  '+229',
  '+230',
  '+231',
  '+232',
  '+233',
  '+234',
  '+235',
  '+236',
  '+237',
  '+238',
  '+239',
  '+240',
  '+241',
  '+242',
  '+243',
  '+244',
  '+245',
  '+248',
  '+249',
  '+250',
  '+251',
  '+252',
  '+253',
  '+254',
  '+255',
  '+256',
  '+257',
  '+258',
  '+260',
  '+261',
  '+262',
  '+263',
  '+264',
  '+265',
  '+266',
  '+267',
  '+268',
  '+269',
  '+290',
  '+291',
  '+297',
  '+298',
  '+299',
  '+350',
  '+351',
  '+352',
  '+353',
  '+354',
  '+355',
  '+356',
  '+357',
  '+358',
  '+359',
  '+370',
  '+371',
  '+372',
  '+373',
  '+374',
  '+375',
  '+376',
  '+377',
  '+378',
  '+380',
  '+381',
  '+382',
  '+385',
  '+386',
  '+387',
  '+389',
  '+420',
  '+421',
  '+423',
  '+500',
  '+501',
  '+502',
  '+503',
  '+504',
  '+505',
  '+506',
  '+507',
  '+508',
  '+509',
  '+590',
  '+591',
  '+592',
  '+593',
  '+595',
  '+597',
  '+598',
  '+599',
  '+670',
  '+672',
  '+673',
  '+674',
  '+675',
  '+676',
  '+677',
  '+678',
  '+679',
  '+680',
  '+681',
  '+682',
  '+683',
  '+685',
  '+686',
  '+687',
  '+688',
  '+689',
  '+690',
  '+691',
  '+692',
  '+850',
  '+852',
  '+853',
  '+855',
  '+856',
  '+870',
  '+880',
  '+886',
  '+960',
  '+961',
  '+962',
  '+963',
  '+964',
  '+965',
  '+966',
  '+967',
  '+968',
  '+971',
  '+972',
  '+973',
  '+974',
  '+975',
  '+976',
  '+977',
  '+992',
  '+993',
  '+994',
  '+995',
  '+996',
  '+998',
  '+1242',
  '+1246',
  '+1264',
  '+1268',
  '+1284',
  '+1340',
  '+1345',
  '+1441',
  '+1473',
  '+1599',
  '+1649',
  '+1664',
  '+1670',
  '+1671',
  '+1684',
  '+1758',
  '+1767',
  '+1784',
  '+1809',
  '+1868',
  '+1869',
  '+1876',
]

export const colourStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: '3px',
    backgroundColor: '#f5f5f5',
    padding: '5px 5px 5px 15px',
    fontSize: '20px',
    letterSpacing: '0',
    outline: 'none',
    border: '0px',
    boxShadow: 'none',
    resize: 'none',
    fontWeight: 'normal',
    height: 'auto',
    marginVottom: '40px',
  }),
}

export const colourStylesLg = {
  control: (styles) => ({
    ...styles,
    borderRadius: '5px',
    backgroundColor: '#ededed',
    padding: '0px',
    fontSize: '15px',
    letterSpacing: '0',
    outline: 'none',
    border: '0px',
    boxShadow: 'none',
    resize: 'none',
    fontWeight: 'normal',
    height: 'auto',
    marginVottom: '40px',
    minHeight: '0px',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: '0px 0px 0px 0px',
  }),
}

export const colourStyles2 = {
  control: (styles) => ({
    ...styles,
    borderRadius: '5px',
    padding: '0.5px',
    marginRight: '0px',
    border: '1px solid #b0b0b0',
    outline: 'none',
    boxShadow: 'none',
    resize: 'none',
  }),
}
export const TYPE_REQ_CALL_BACK_CheckYourEligibility_LG = 'PPC_PAPcheck'
export const TYPE_REQ_CALL_BACK_LG = 'AH_Callback'
export const TYPE_REQ_CALL_BACK = 'requestCallback'
export const TYPE_REQ_CALL_BACK_PPC = 'PPC_requestCallback'
export const TYPE_PPC = 'PPC'
export const TYPE_TELUS = 'Telus'
export const TYPE_PPC_CHECK_ELIGIBILITY = 'ppc_CheckEligibility'
export const TYPE_CONTACT_US = 'contactUs'
export const TYPE_INDSTRY_REPORTS = 'industryReports'
export const TYPE_BROCHURE = 'brochure'
export const TYPE_TALK_TO_EXPERT = 'talkToExpert'
export const TYPE_FORM = 'form'
export const TYPE_FREE_DEMO = 'freeDemo'
export const TYPE_DOWNLOAD_BROCHURE = 'downloadBrochure'
export const TYPE_PPC_DOWNLOAD_BROCHURE = 'PPC_downloadBrochure'
export const TYPE_PPC_BANGALORE_DOWNLOAD_BROCHURE =
  'PPC_bangalore_downloadBrochure'
export const TYPE_PPC_NCR_DOWNLOAD_BROCHURE = 'PPC_ncr_downloadBrochure'
export const TYPE_PPC_FB_DOWNLOAD_BROCHURE = 'PPC_fb_downloadBrochure'
export const TYPE_PPC_YTB_DOWNLOAD_BROCHURE = 'PPC_ytb_downloadBrochure'
export const TYPE_SIGNUP_FOR_DEMO = 'signUpForDemo'
export const TYPE_PPC_SIGNUP_FOR_DEMO = 'PPC_signUpForDemo'
export const TYPE_PPC_BANGALORE_SIGNUP_FOR_DEMO = 'PPC_bangalore_signUpForDemo'
export const TYPE_PPC_NCR_SIGNUP_FOR_DEMO = 'PPC_ncr_signUpForDemo'
export const TYPE_PPC_FB_SIGNUP_FOR_DEMO = 'PPC_fb_signUpForDemo'
export const TYPE_PPC_YTB_SIGNUP_FOR_DEMO = 'PPC_ytb_signUpForDemo'
export const TYPE_AVAIL_THE_OFFER = 'availTheOffer'
export const TYPE_INQUIRE_NOW = 'inquireNow'
export const TYPE_REACT_OUT_US = 'reachOutUs'
export const TYPE_BOOST_CAREER = 'boostCareer'
export const TYPE_TAKE_DEMO = 'takeDemo'
export const TYPE_PG_DIPLOMA = 'PG'
export const TYPE_PG_DIPLOMA_DOWNLOAD_BROCHURE = 'PG_downloadBrochure'
export const TYPE_PGDP_DOWNLOAD_BROCHURE = 'PG_downloadBrochure'
export const TYPE_PGCP_DOWNLOAD_BROCHURE = 'PG_downloadBrochure'
export const TYPE_PPC_Bing_SIGNUP_FOR_DEMO = 'PPC_bing_signUpForDemo'
export const TYPE_PPC_Brand_SIGNUP_FOR_DEMO = 'PPC_brand_signUpForDemo'
export const TYPE_PPC_Gh_Blr_SIGNUP_FOR_DEMO = 'PPC_gh_blr_signUpForDemo'
export const TYPE_PPC_GH_NCR_SIGNUP_FOR_DEMO = 'PPC_gh_ncr_signUpForDemo'
export const TYPE_PPC_DS_BLR_SIGNUP_FOR_DEMO = 'PPC_ds_blr_signUpForDemo'
export const TYPE_PPC_BING_DOWNLOAD_BROCHURE = 'PPC_bing_downloadBrochure'
export const TYPE_PPC_BRAND_DOWNLOAD_BROCHURE = 'PPC_brand_downloadBrochure'
export const TYPE_PPC_GH_BLR_DOWNLOAD_BROCHURE = 'PPC_gh_blr_downloadBrochure'
export const TYPE_PPC_GH_NCR_DOWNLOAD_BROCHURE = 'PPC_gh_ncr_downloadBrochure'
export const TYPE_PPC_DS_BLR_DOWNLOAD_BROCHURE = 'PPC_ds_blr_downloadBrochure'
export const TYPE_PPC_BING_REQ_CALL_BACK = 'PPC_bing_requestCallback'
export const TYPE_PPC_BRAND_REQ_CALL_BACK = 'PPC_brand_requestCallback'
export const TYPE_PPC_GH_BLR_REQ_CALL_BACK = 'PPC_gh_blr_requestCallback'
export const TYPE_PPC_GH_NCR_REQ_CALL_BACK = 'PPC_gh_ncr_requestCallback'
export const TYPE_PPC_DS_BLR_REQ_CALL_BACK = 'PPC_ds_blr_requestCallback'
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

export const formatGroupLabel = (data) => {
  return (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )
}

export const lgPages = ['lg', 'lg-bangalore', 'lg-ncr', 'lg-tis', 'lg-fb', 'lg-ytb','lg-bangalore2','lg-ncr1','lg-ncr2']
export const courseSlugOfLgPage  = ['data-science-specialization-course']
export const courseLgPagesSlug = ['lg-ncr-iit']
export const selectedLgPages = [
  'Brand_Bing',
  'gh_ds_bangalore_bing',
  'gh_ds_delhi_bing',
  'gh_ds_noida_bing',
  'gh_ds_gurgaon_bing',
  'ds_ncr_bing',
  'ds_bangalore_bing1',
  'ds_bangalore_bing2',
  'Brand_ppc',
  'gh_ds_bangalore',
  'gh_ds_ncr',
  'ds_bangalore1',
  'ds_bangalore2',
]

export const selectedPagesForDSSC = [
  'gh_ds_bangalore_bing',
  'gh_ds_delhi_bing',
  'gh_ds_noida_bing',
  'gh_ds_gurgaon_bing',
  'Brand_ppc',
  'gh_ds_bangalore',
  'gh_ds_ncr',
]

export const selectedPagesForDSSCNcr = ['Brand_Bing', 'ds_ncr_bing']
export const selectedPagesForDSSCBangalore = [
  'ds_bangalore_bing1',
  'ds_bangalore_bing2',
  'ds_bangalore1',
  'ds_bangalore2',
]

export const listOfNoIndexNoFollow = [
  'data-analytics-certification-course',
  'data-science-specialization-courses-ytlp',
  'pgprogram',
  'business-analytics-data-science-coursen2',
  dataScienceWithAiSpecializationSlug?.slug,
]

export const checkExtraUrl = window.location.href.includes('?')
export const splitUrl = window.location.href.split('/')

export const getSlugFromUrlByIndex = (index, full) => {
  const url = full
    ? window.location.href.split('/')
    : window.location.pathname.split('/')
  if (url?.[index]) return url[index]
  return null
}

export const getSlugAfterFromUrlByIndex = (index, full) => {
  const endUrl = getSlugFromUrlByIndex(index, full)
  if (endUrl) return endUrl.split('?')[0]
  return null
}

export const pathNameUrl = [
  '/',
  '/upcoming-batches',
  '/courses',
  '/offers',
  '/why-us',
  '/enterprises',
  '/corporates-training-courses',
  '/about-faculty',
  '/free-resources',
  '/terms-conditions',
  '/reviews-complaints-testimonials',
  '/colleges-universities-training-courses',
  '/checkout',
  '/contact-us',
  '/contact-us/lg',
  '/courses/lg'
]

export const homeCourseOrder = [
  'pg-in-data-analytics',
  'data-science-specialization-course',
  'artificial-intelligence-certification-courses-online',
]

export const listOfNoIndexNoFollowDuplicate = [
  'gh_ds_bangalore_bing',
  'gh_ds_delhi_bing',
  'gh_ds_noida_bing',
  'gh_ds_gurgaon_bing',
  'Brand_ppc',
  'gh_ds_bangalore',
  'gh_ds_ncr',
]

export const bottomNumber = '+91-9667772573'

export const typeForPPCBing = [
  'Brand_Bing',
  'gh_ds_bangalore_bing',
  'gh_ds_delhi_bing',
  'gh_ds_noida_bing',
  'gh_ds_gurgaon_bing',
  'ds_ncr_bing',
]

export const typeForPPCBrand = ['Brand_ppc']
export const typeForPPCGHBLR = ['gh_ds_bangalore']
export const typeForPPCGHNCR = ['gh_ds_ncr']
export const typeForPPCDSBLR = ['ds_bangalore1', 'ds_bangalore2']

export const lgPageConst = {
  lgPageSlugList : ['/contact-us/lg' , '/courses/lg', '/placements/lg'],
  callUs : {country_code : "+91",
    end_time : "19:00:00",
    number : "9555525908",
    start_time : "10:00:00"}
}